
.H1{
    font-size:50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-top:70px;
    color:#FFFFFF;  
}

.H1_pc{
    font-size:70px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    margin-top:55px;
    color:#FFFFFF;  
}

.H2{
    padding-top: 15px;
    color:#FFFFFF;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.H2_pc{
    padding-top: 15px;
    color:#FFFFFF;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    margin-top: -5px;
}

.H3{
    padding-top: 5px;
    color:#FFFFFF;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

}

.H3_pc{
    padding-top: 5px;
    color:#FFFFFF;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    padding-bottom: 5px;

}

.sub2PcH1{
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding-bottom: 7px;
    font-size: 25px;
    color:'#000000'
}

.sub2PcH2{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color:#000000;
    font-size: 14px;
    opacity:0.7
}

.sub2PcCircles{
    width:85px;
    height:85px;
    margin-right:15px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.sub2PcCircles2{
    width:80px;
    height:80px;
    margin-left:15px;
}


.buynowBtn:hover{
    background-color:#38BE8C;
}

.fade-appear{
    opacity:0;
}

.fade-appear.fade-appear-active{
    opacity: 1;
    transition: opacity 1000ms linear 300ms;
}

.fadeout-leave{
    opacity: 1;
}

.fadeout-leave.fadeout-leave-active{
    opacity: 0;
    transition: opacity 300ms linear;
}

.power_rating-carrier-m{
    /* background-color:blue; */
    /* background: url('../images/new_images/desc_bg.png'); */
    /* background-repeat: initial; */
    background-image: linear-gradient(to top,#009FF1,#202F6D);
    overflow: hidden;
    color:#FFFFFF;
}


.power_rating-carrier-pc{
    background-image: linear-gradient(to top,#009FF1,#202F6D);
    overflow: hidden;
    color:#FFFFFF;
}


.design_icon{
    background-color:#f4f4f4;
    border:2px solid #6AA75E ;
    width: 80px;
    height: 80px;
}

.design_icon2{
    width: 80px;
    height: 80px;
    background-color:#f4f4f4;
    border:2px solid #B048EB ; 
}

.design_icon3{
    background-color:#f4f4f4;
    border:2px solid #E65550 ; 
    width: 80px;
    height: 80px;
}

.footer_wrapper{
    /* background-color: lightcoral; */
    padding:'40px 0px 100px'
}

.footer_logo_holder{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding:50px 20px 30px;
    height: 110px;
}

.footer_logo_carrier{
    /* background-color: ; */
    margin-top: -50px;
    opacity: 0.8;
}

.footer_h1{
    font-family: Roboto,sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.footer_h2{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    padding:3px 0px 3px; 
    opacity: 0.7;
}

.last_footer_wrapper{
    background-color: #F4F4F4;
    text-align: center;
    padding:20px 20px 40px;
    font-size: 13px;
}

.last_footer_wrapper_pc{
    background-color: #F4F4F4;
    text-align: center;
    padding:20px 20px 80px;
    font-size: 13px;
}

.testimony_wrapper_m{
    background-image: linear-gradient(to top,#FBFBFD,#E9EAE5);
    padding:15px 0px 30px;
}

.testimony_wrapper_pc{
    background-image: linear-gradient(to top,#FBFBFD,#E9EAE5);
    padding:15px 15px 30px;
}

.award_wrapper_pc{
    /* background-image: linear-gradient(to top,#76461B,#E9EAE5); */
    background:url('../images/new_images/award_bg.jpeg') no-repeat center center fixed;
    padding:30px 15px 50px;
    margin:0px auto -20px;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color:'#FFFFFF';
}


.bubble_profile_carrier{
    position: absolute;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    /* background-color: moccasin; */
    justify-content: flex-start;
    align-items: flex-start;
}

.bubble_profile_wrapper{
    width:50px;
    height:50px;
    border-radius: 60px;
    background-color: #FBFBFA;
    /* background-color: rgba(255,255,255,0.99); */
    /* border:1px solid #F7F7F8; */
}

.bubble_profile_name{
    color:#000000;
    padding: 5px 0px 0px 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto,sans-serif;
}

.testimony_wrapper{
    padding: 30px 10px 30px;
    position: relative;
    /* background-color: moccasin; */
}

.testimony_wrapper{
    padding: 30px 10px 30px;
    position: relative;
    /* background-color: moccasin; */
}

.testimony_wrapper_bubble_pc{
    flex:1;
    padding: 0px 10px 0px;
}

.bubble_mgs_wrapper{
    background-color: rgba(255,255,255,0.75);
    margin:0px 0px 0px 25px;
    padding: 10px 10px 15px;
    font-size: 13px;
    color: #212121;
    text-indent: 15px;
    border-radius: 10px;
    border:1px solid #E9EAE5;
    text-align: left;
}